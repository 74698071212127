import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import RenewOrderItem from './RenewOrderItem';
import { ActionFilterRules, Entities } from '../../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderList = useSelector((state) => state.order.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const orderId = parseInt(searchParams.get('id')) || location?.state?.id;
  const attendeeEmail = searchParams.get('email') || location?.state?.email;
  const [orderItem, setOrderItem] = useState(null);

  const getList = (orderId) => {
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.order.getAll)) {
      dispatch(Actions.orderActions.getOrderDetailsAction(orderId));
    }
  };

  const headers = [
    { key: 'orderId', label: t('common.orderId') },
    { key: 'id', label: t('common.orderItemId') },
    { key: 'orderItemStatus', label: t('orderItem.status') },
    { key: 'userArea', label: t('orderItem.userArea') },
    { key: 'redateRight', label: t('orderItem.redateRight') },
    { key: 'undatedPackageCount', label: t('order.undated_package_count') },
    { key: 'datedMeetingCount', label: t('orderItem.datedMeetingCount') },
    { key: 'totalAmount', label: t('order.total_amount') },
    { key: 'netAmount', label: t('order.net_amount') },
    { key: 'productType', label: t('orderItem.productType') },
    { key: 'trialStatus', label: t('orderItem.trialStatus') },
    { key: 'options', label: t('orderItem.options') },
  ];

  const editAction = (val) => {
    dispatch(Actions.orderActions.cleanState());
    dispatch(Actions.locationActions.cleanState(true));
    navigate('/order-item-update', {
      state: { id: val.id, userArea: val.userArea, orderId: orderId },
    });
  };

  const renewAction = (val) => {
    val.email = attendeeEmail;
    setOrderItem(val);
  };

  useEffect(() => {
    getList(orderId);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      {orderItem === null && (
        <React.Fragment>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <HeadTags hSize={1} text={t('order.orderItem_list')} strong />
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-lg-12">
              <Card
                body={
                  <div>
                    <Table
                      // searchAble
                      data={orderList}
                      headers={headers}
                      striped
                      bordered
                      page={Entities.order}
                      edit={(val) => editAction(val)}
                      renewOrderItem={(val) => renewAction(val)}
                      currentUserClaim={currentUserClaim}
                      exportName={Entities.order.exportName.orderItem}
                      noUtc={false}
                      filterActionsRule={true}
                      filterRule={ActionFilterRules.order}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {orderItem !== null && (
        <RenewOrderItem
          orderItem={orderItem}
          tableObject={{ data: orderItem, headers: headers }}
          updateList={(val) => {
            setOrderItem(null);
            getList(val);
          }}
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.getAll,
    content
  );
};
export default List;
