import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const banner = useSelector((state) => state.banner.getActiveBannerByZone);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;

  useEffect(() => {
    if (lengthCurrentUserClaim) {
      dispatch(Actions.bannerActions.getByZoneAction());
    }
  }, [lengthCurrentUserClaim]);

  return (
    <div className="container-fluid sticky-top">
      <div className="row">
        <div
          className="col-12 p-0"
          role="button"
          onClick={() => navigate('/order/package')}
        >
          {banner?.pictureWeb && (
            <div className="d-lg-block d-none sticky-top">
              <img
                src={banner?.pictureWeb}
                alt="pictureWeb"
                className="img-fluid p-0"
                width={'100%'}
                height={'100%'}
                style={{
                  minHeight: '60px',
                  maxHeight: '90px',
                }}
              />
            </div>
          )}
          {banner?.pictureMobile && (
            <div className="d-none d-md-block d-lg-none sticky-top">
              <img
                src={banner?.pictureMobile}
                alt="pictureMobile"
                className="img-fluid p-0"
                width={'100%'}
                style={{
                  maxHeight: '100px',
                }}
              />
            </div>
          )}
          {banner?.pictureMobile && (
            <div className="d-md-none d-flex sticky-top">
              <img
                src={banner?.pictureMobile}
                alt="pictureMobile"
                className="img-fluid p-0"
                width={'100%'}
                height={'100%'}
                style={{
                  maxHeight: '80px',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
