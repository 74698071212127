import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import useClickOutside from '../../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/tr';

const CalendarDropdown = ({ title, id, svg, func, selectedOptions }) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedDayofWeek, setSelectedDayofWeek] = useState(() => {
    const initialSelectedOptions = selectedOptions?.dayOfWeek || [];
    const transformedOptions = {};

    initialSelectedOptions.forEach((date) => {
      const parts = date.split('T');
      const transformedDate = parts[0];
      transformedOptions[transformedDate] = true;
    });

    return transformedOptions;
  });

  const [selectedHoursofDay, setSelectedHoursofDay] = useState(() => {
    const initialSelectedOptions = selectedOptions?.timeInterval || [];
    const transformedTimeIntervals = {};

    initialSelectedOptions.forEach((interval) => {
      const parts = interval.split('-');
      const startDate = parts[2].split('T')[1].slice(0, 2);
      const endDate = parts[5].split('T')[1].slice(0, 2);

      const timeIntervalKey = `${startDate} - ${endDate}`;
      transformedTimeIntervals[timeIntervalKey] = true;
    });

    return transformedTimeIntervals;
  });

  const [selectedCalendarDay, setSelectedCalendarDay] = useState(() => {
    const initialSelectedOptions = selectedOptions?.selectedDate || [];
    const transformedOptions = {};

    initialSelectedOptions.forEach((date) => {
      const parts = date.split('T');
      const transformedDate = parts[0];
      transformedOptions[transformedDate] = true;
    });

    return transformedOptions;
  });
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState(() => {
    const initialSelectedOptions = selectedOptions?.selectedTime || [];
    const transformedselectedTime = {};

    initialSelectedOptions.forEach((interval) => {
      const parts = interval.split('-');
      const startDate = parts[2].split('T')[1].slice(0, 2);

      const timeIntervalKey = `${startDate}:00`;
      transformedselectedTime[timeIntervalKey] = true;
    });

    return transformedselectedTime;
  });

  const today = moment().locale(locale);
  const [selectedWeek, setSelectedWeek] = useState(today);
  let currentWeek = [];
  for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    let nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
    currentWeek.push(moment(nextDate));
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const justifyContentClass = isMobile
    ? 'justify-content-start'
    : 'justify-content-center';
  const dropdownRef = useClickOutside(() => {
    if (isOpen === true) {
      setIsOpen(false);
    }
  });

  const [currentTime, setCurrentTime] = useState(new Date());
  const calendarRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // update the current time every minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const calendarEl = calendarRef?.current;
    const timeSlotEl = calendarEl?.querySelector(
      `[data-time="${currentTime.getHours()}:00"]`
    );

    if (timeSlotEl) {
      calendarEl.scrollTop = timeSlotEl?.offsetTop - 75;
    }
  }, [currentTime]);

  const previousWeek = () => {
    let prevDates = [];
    let prevWeek = [];
    for (let dayOffset = 7; dayOffset > 0; dayOffset--) {
      let prevDate = moment(selectedWeek).subtract(dayOffset, 'days').toDate();
      prevDates.push(moment(prevDate));
    }
    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      var a = moment(prevDates.at(0)).add(dayOffset, 'days').toDate();
      prevWeek.push(moment(a));
    }
    setSelectedWeek(() => moment(prevWeek.at(0)));
  };

  const nextWeek = () => {
    let nextDates = [];
    let nxtWeek = [];
    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      let nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
      nextDates.push(moment(nextDate));
    }
    for (let dayOffset = 1; dayOffset < 8; dayOffset++) {
      var a = moment(nextDates.at(6)).add(dayOffset, 'days').toDate();
      nxtWeek.push(moment(a));
    }
    setSelectedWeek(() => moment(nxtWeek.at(0)));
  };

  const anotherDates = [];
  const reportDates = [];
  for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    const nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
    reportDates.push(moment(nextDate));
    anotherDates.push(moment(nextDate));
    anotherDates.push(moment(...anotherDates, reportDates));
  }

  const setDayOfWeeks = () => {
    const startingDay = moment().startOf('week');
    const dayOfWeeks = [];

    for (let i = 0; i < 7; i++) {
      const currentDay = moment(startingDay);
      dayOfWeeks.push(currentDay);
      startingDay.add(1, 'day');
    }
    return dayOfWeeks;
  };

  const timeSlotsNormal = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 60) {
      timeSlotsNormal.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
      );
    }
  }
  const timeSlotsSmall = [];
  for (let hour = 0; hour < 24; hour += 4) {
    const startHour = hour === 24 ? '00' : hour.toString().padStart(2, '0');
    const endHour =
      hour + 4 === 24 ? '00' : (hour + 4).toString().padStart(2, '0');
    timeSlotsSmall.push(`${startHour} - ${endHour}`);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [selectedTab, setSelectedTab] = useState('general');
  const changeTab = (tab) => {
    setSelectedTab(tab);
  };

  let mainText = `${moment(selectedWeek).format('MMM D')} - ${moment(
    selectedWeek
  )
    .add(6, 'days')
    .format('MMM D, YYYY')}`;

  const selectedCount =
    Object.values(selectedDayofWeek).filter(Boolean).length +
    Object.values(selectedHoursofDay).filter(Boolean).length +
    Object.values(selectedCalendarDay).filter(Boolean).length +
    Object.values(selectedTimeOfDay).filter(Boolean).length;
  return (
    <div ref={dropdownRef} className="calendar-dropdown">
      <div
        className={`dropdown-header d-flex align-items-center ${justifyContentClass} `}
        onClick={toggleDropdown}
        role="button"
        id={id}
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        style={{ position: 'relative' }}
      >
        {svg && (
          <div style={{ position: 'relative' }}>
            <span className="position-absolute top-0 end-0 badge rounded-pill bg-danger">
              {selectedCount > 0 ? selectedCount : ''}
            </span>
            <img src={svg} alt={svg} />
          </div>
        )}
        {title && (
          <span
            className=" ps-1 d-inline-flex text-wrap p-0 m-0"
            style={{ fontSize: '0.75rem' }}
          >
            {title}
          </span>
        )}
        <FontAwesomeIcon
          className={`dropdown-icon d-flex ms-sm-1 ${isOpen ? 'open' : ''}`}
          icon={isOpen ? faChevronUp : faChevronDown}
          style={{ fontSize: '1rem' }}
        />
      </div>
      {isOpen && (
        <div
          className="dropdown-menu dropdown-menu-end show fullscreen-dropdown"
          aria-labelledby={id}
          style={{ position: 'absolute', top: '50', left: '0', width: '100%' }}
        >
          {!isMobile ? (
            <div
              className="rounded-4"
              style={{
                maxHeight: 'calc(100vh - 166.667px)',
                overflowY: 'auto',
              }}
            >
              <div className="d-flex relative px-5 py-3 rounded-3 bg-white ">
                <div className="overflow-y-auto w-50 pe-3 ">
                  <p className="text-base font-medium text-black">
                    {t('dateTimeFilter.choose_a_general_time')}
                  </p>
                  <section>
                    <div className="mt-4 ">
                      {t('dateTimeFilter.days_of_the_week')}
                    </div>
                    <div>
                      <div className="d-flex flex-wrap justify-content-center">
                        {setDayOfWeeks().map((day, index) => (
                          <div
                            key={`${day}-${index}`}
                            className="d-flex col-3 align-items-center justify-content-center"
                          >
                            <button
                              key={`${day.format('YYYY-MM-DD')}-${index}`}
                              id={day.format('YYYY-MM-DD')}
                              className={`btn btn-default border border-1 rounded-4 font-normal my-2 mx-3 d-inline-flex align-items-center justify-content-center flex-grow-0 ${
                                selectedDayofWeek[day.format('YYYY-MM-DD')]
                                  ? 'selected bg-t-calendar text-white'
                                  : 'bg-light'
                              }`}
                              onClick={(e) => {
                                func({ id: 'dayOfWeek', date: e.target.id });

                                setSelectedDayofWeek({
                                  ...selectedDayofWeek,
                                  [day.format('YYYY-MM-DD')]:
                                    !selectedDayofWeek[
                                      day.format('YYYY-MM-DD')
                                    ],
                                });
                              }}
                            >
                              {day.format('ddd')}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                  <section className="mt-3">
                    <div> {t('dateTimeFilter.time_of_the_day')}</div>
                    <div>
                      <div className="d-flex flex-wrap justify-content-center">
                        {timeSlotsSmall.map((time, index) => (
                          <div
                            key={`${time}-${index}`}
                            className="d-flex col-lg-4 col-3 align-items-center justify-content-center"
                          >
                            <button
                              key={`${time}-${index}`}
                              className={`btn btn-default border  border-1 rounded-4 font-normal text-nowrap  m-2 d-inline-flex align-items-center justify-content-center ${
                                selectedHoursofDay[time]
                                  ? 'selected bg-t-calendar text-white'
                                  : 'bg-light'
                              }`}
                              data-time={time}
                              id={time}
                              onClick={(e) => {
                                func({ id: 'timeInterval', date: e.target.id });
                                setSelectedHoursofDay({
                                  ...selectedHoursofDay,
                                  [time]: !selectedHoursofDay[time],
                                });
                              }}
                            >
                              {time}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
                <div className="overflow-y-auto w-50 ps-3">
                  <p className="text-base font-medium text-black">
                    {t('dateTimeFilter.choose_a_specific_time')}
                  </p>
                  <div className="row justify-content-between mb-3">
                    <div
                      className="col-3 d-flex align-items-center justify-content-start text-center"
                      role={'button'}
                      onClick={
                        selectedWeek.date() !== today.date()
                          ? previousWeek
                          : null
                      }
                    >
                      <div className="col-1 mb-1">
                        {selectedWeek.date() !== today.date() ? (
                          <FontAwesomeIcon icon={faChevronLeft} />
                        ) : undefined}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <h4>{mainText}</h4>
                    </div>
                    <div
                      className="col-3 d-flex align-items-center  justify-content-end  text-center"
                      role={'button'}
                      onClick={nextWeek}
                    >
                      <div className="col-1 mb-1">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 calendar-scroll"
                    ref={calendarRef}
                    style={{ width: '100%', overflowY: 'hidden' }}
                  >
                    <div className="d-flex align-items-center justify-content-between text-center">
                      {reportDates.map((day, index) => (
                        <button
                          key={`${day.format('YYYY-MM-DD')}-${index}`}
                          className={`btn btn-default  rounded-4  d-inline-flex align-items-center justify-content-center flex-grow-1 ${
                            selectedCalendarDay[day.format('YYYY-MM-DD')]
                              ? 'selected bg-t-calendar text-white'
                              : 'bg-light'
                          }`}
                          id={day.format('YYYY-MM-DD')}
                          onClick={(e) => {
                            func({ id: 'selectedDate', date: e.target.id });
                            setSelectedCalendarDay({
                              ...selectedCalendarDay,
                              [day.format('YYYY-MM-DD')]:
                                !selectedCalendarDay[day.format('YYYY-MM-DD')],
                            });
                          }}
                        >
                          {day.format('ddd')}
                          <br />
                          {day.format('D')}
                        </button>
                      ))}
                    </div>
                  </div>
                  <section className="mt-3">
                    <div className="mt-4">
                      {t('dateTimeFilter.hours_of_the_day')}
                    </div>
                    <div>
                      <div className="d-flex flex-wrap justify-content-center">
                        {timeSlotsNormal.map((time, index) => (
                          <div
                            key={`${time}-${index}`}
                            className="d-flex col-xl-2 col-3 col-sm-3 col-md-3 align-items-center justify-content-center m-0 p-0"
                          >
                            <button
                              key={`${time}-${index}`}
                              className={`btn btn-default border  border-1 rounded-4 font-normal m-1 p-1 d-inline-flex align-items-center justify-content-center flex-grow-0 ${
                                selectedTimeOfDay[time]
                                  ? 'selected bg-t-calendar text-white'
                                  : 'bg-light'
                              }`}
                              data-time={time}
                              id={time}
                              onClick={(e) => {
                                func({ id: 'selectedTime', date: e.target.id });
                                setSelectedTimeOfDay({
                                  ...selectedTimeOfDay,
                                  [time]: !selectedTimeOfDay[time],
                                });
                              }}
                            >
                              {time}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          ) : (
            <div className="dropdown-menu dropdown-menu-end show  fullscreen-dropdown">
              <div className="tabs-container d-flex  justify-content-center">
                <button
                  className={`btn btn-default border border-1  mx-2 rounded-4 ${
                    isMobile ? 'active' : ''
                  } ${selectedTab === `general` ? 'bg-custom' : ''}`}
                  onClick={() => changeTab('general')}
                >
                  {t('dateTimeFilter.general_times')}
                </button>
                <button
                  className={`btn btn-default border border-1  mx-2 rounded-4 ${
                    !isMobile ? 'active' : ''
                  } ${selectedTab === `specific` ? 'bg-custom' : ''}`}
                  onClick={() => changeTab('specific')}
                >
                  {t('dateTimeFilter.specific_times')}
                </button>
              </div>
              {isMobile && selectedTab === 'general' ? (
                <div className="fullscreen-content">
                  {/* General Times Content */}
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                      <p className="text-base font-medium text-black">
                        {t('dateTimeFilter.choose_a_general_time')}
                      </p>
                      <section>
                        <div className="mt-lg-4 ">
                          {' '}
                          {t('dateTimeFilter.days_of_the_week')}
                        </div>
                        <div>
                          <div className="d-flex flex-wrap justify-content-center">
                            {setDayOfWeeks().map((day, index) => (
                              <div
                                key={`${day}-${index}`}
                                className="d-flex col-3 align-items-center justify-content-center"
                              >
                                <button
                                  key={`${day.format('YYYY-MM-DD')}-${index}`}
                                  id={day.format('YYYY-MM-DD')}
                                  className={`btn btn-default border border-1 rounded-4 font-normal my-2 mx-3 d-inline-flex align-items-center justify-content-center flex-grow-0 ${
                                    selectedDayofWeek[day.format('YYYY-MM-DD')]
                                      ? 'selected bg-t-calendar text-white'
                                      : 'bg-light'
                                  }`}
                                  onClick={(e) => {
                                    func({
                                      id: 'dayOfWeek',
                                      date: e.target.id,
                                    });

                                    setSelectedDayofWeek({
                                      ...selectedDayofWeek,
                                      [day.format('YYYY-MM-DD')]:
                                        !selectedDayofWeek[
                                          day.format('YYYY-MM-DD')
                                        ],
                                    });
                                  }}
                                >
                                  {day.format('ddd')}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                      <section className="mt-lg-3">
                        <div className="mt-4">
                          {t('dateTimeFilter.time_of_the_day')}
                        </div>
                        <div>
                          <div className="d-flex flex-wrap justify-content-center">
                            {timeSlotsSmall.map((time, index) => (
                              <div
                                key={`${time}-${index}`}
                                className="d-flex col-4 align-items-center justify-content-center"
                              >
                                <button
                                  key={`${time}-${index}`}
                                  className={`btn btn-default border  border-1 rounded-4 font-normal text-nowrap  m-2 d-inline-flex align-items-center justify-content-center ${
                                    selectedHoursofDay[time]
                                      ? 'selected bg-t-calendar text-white'
                                      : 'bg-light'
                                  }`}
                                  data-time={time}
                                  id={time}
                                  onClick={(e) => {
                                    func({
                                      id: 'timeInterval',
                                      date: e.target.id,
                                    });
                                    setSelectedHoursofDay({
                                      ...selectedHoursofDay,
                                      [time]: !selectedHoursofDay[time],
                                    });
                                  }}
                                >
                                  {time}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              ) : isMobile && selectedTab === 'specific' ? (
                <div className="fullscreen-content">
                  {/* Specific Times Content */}
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                      <p className="text-base font-medium text-black">
                        {t('dateTimeFilter.choose_a_specific_time')}
                      </p>
                      <div className="row justify-content-between mb-3">
                        <div
                          className="col-3 d-flex align-items-center justify-content-start text-center"
                          role={'button'}
                          onClick={
                            selectedWeek.date() !== today.date()
                              ? previousWeek
                              : null
                          }
                        >
                          <div className="col-1 mb-1">
                            {selectedWeek.date() !== today.date() ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : undefined}
                          </div>
                        </div>
                        <div className="col-6 text-center">
                          <h4>{mainText}</h4>
                        </div>
                        <div
                          className="col-3 d-flex align-items-center justify-content-end text-center"
                          role={'button'}
                          onClick={nextWeek}
                        >
                          <div className="col-1 mb-1">
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-12 calendar-scroll"
                        ref={calendarRef}
                        style={{ width: '100%', overflowY: 'hidden' }}
                      >
                        <div className="d-flex align-items-center justify-content-between text-center">
                          {reportDates.map((day, index) => (
                            <button
                              key={`${day.format('YYYY-MM-DD')}-${index}`}
                              className={`btn btn-default  rounded-4  d-inline-flex align-items-center justify-content-center flex-grow-1 ${
                                selectedCalendarDay[day.format('YYYY-MM-DD')]
                                  ? 'selected bg-t-calendar text-white'
                                  : 'bg-light'
                              }`}
                              id={day.format('YYYY-MM-DD')}
                              onClick={(e) => {
                                func({ id: 'selectedDate', date: e.target.id });
                                setSelectedCalendarDay({
                                  ...selectedCalendarDay,
                                  [day.format('YYYY-MM-DD')]:
                                    !selectedCalendarDay[
                                      day.format('YYYY-MM-DD')
                                    ],
                                });
                              }}
                            >
                              {day.format('ddd')}
                              <br />
                              {day.format('D')}
                            </button>
                          ))}
                        </div>
                      </div>
                      <section className="mt-3">
                        <div className="mt-4">
                          {t('dateTimeFilter.hours_of_the_day')}
                        </div>
                        <div>
                          <div className="d-flex flex-wrap justify-content-center">
                            {timeSlotsNormal.map((time, index) => (
                              <div
                                key={`${time}-${index}`}
                                className="d-flex col-3 align-items-center justify-content-center"
                              >
                                <button
                                  key={`${time}-${index}`}
                                  className={`btn btn-default border  border-1 rounded-4 font-normal m-1 p-1 d-inline-flex align-items-center justify-content-center flex-grow-0 ${
                                    selectedTimeOfDay[time]
                                      ? 'selected bg-t-calendar text-white'
                                      : 'bg-light'
                                  }`}
                                  data-time={time}
                                  id={time}
                                  onClick={(e) => {
                                    func({
                                      id: 'selectedTime',
                                      date: e.target.id,
                                    });
                                    setSelectedTimeOfDay({
                                      ...selectedTimeOfDay,
                                      [time]: !selectedTimeOfDay[time],
                                    });
                                  }}
                                >
                                  {time}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarDropdown;
