import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import SearchButton from '../../../components/Common/Button/SearchButton';
import Toast from '../../../components/Common/Popup/Toast';
import { ButtonSize, InputType } from '../../../utils/ComponentEnums';
import { ActionFilterRules, Entities } from '../../../utils/Enum';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderList = useSelector((state) => state.order.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const timesPerMonthList = useSelector((state) => state.timesPerMonth.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const orderStatus = staticDatas.OrderStatus;
  const userAreaList = staticDatas.UserArea;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
    setMountStatus(false);
  }, []);

  const getList = (func = false) => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.order.getAll) &&
      Object.keys(filterData).length > 0 &&
      mountStatus !== null
    ) {
      const action = Actions.orderActions.getAllAction(filterData);
      setShowLoader(true);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        setDateChanged(true);
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }
  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const editAction = (val) => {
    navigate('/order-update', { state: { id: val.id } });
  };
  const removeAction = (id) => {
    dispatch(Actions.orderActions.removeOrderAction(id));
  };

  const editSupAction = (id) => {
    navigate('/attendee-update', { state: { id: id.attendeeId } });
  };

  const forwardAction = (val) => {
    navigate(`/order-history/${val.supId}`, {
      state: { attendeeId: val.supId },
    });
  };

  const showDetailsAction = (val) => {
    window.open(`/order-item-list?id=${val.id}&email=${val.email}`, '_blank');
  };

  const toOperationAction = (val) => {
    const url = new URL('/operations', window.location.origin);
    const state = {
      attendee: {
        id: val.supId,
        name: val.name ?? '',
        email: val.email,
        phone: val.phone ?? '',
      },
    };
    url.search = new URLSearchParams(state.attendee).toString();
    window.open(url.toString(), '_blank');
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      setDateChanged(true);
      return rest;
    });
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    if (timesPerMonthList.length === 0) {
      dispatch(Actions.timesPerMonthActions.getAllAction());
    }
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const headers = [
    { key: 'id', label: t('common.orderId') },
    { key: 'attendeeName', label: t('order.attendee_name') },
    { key: 'phone', label: t('common.phone') },
    { key: 'orderItemDescription', label: t('order.order_item_description') },
    { key: 'location', label: t('order.table_location') },
    { key: 'manager', label: t('order.manager') },
    { key: 'trialStatus', label: t('orderItem.orderType') },
    { key: 'orderStatus', label: t('order.order_status') },
    { key: 'paymentType', label: t('order.payment_type') },
    { key: 'paymentStatus', label: t('order.payment_status') },
    { key: 'netAmount', label: t('order.net_amount') },
    { key: 'undatedPackageCount', label: t('order.undated_session_count') },
    { key: 'cardPaymentType', label: t('order.cardPaymentType') },
    { key: 'errorMessage', label: t('order.errorMessage'), hide: true },
    // { key: 'totalAmount', label: t('order.total_amount') },
    // { key: 'parasutSync', label: t('order.parasutSync') },
    // { key: 'installmentCount', label: t('order.installmentCount') },
    { key: 'createdAt', label: t('common.created_at') },
    // { key: 'createdBy', label: t('common.created_by') },
    // { key: 'updatedAt', label: t('common.updated_at') },
    // { key: 'updatedBy', label: t('common.updated_by') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('order.order_list')} strong />
      </div>
      <div className="col-12">
        <Card
          body={
            <React.Fragment>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <SearchButton
                  stateName={'order'}
                  size={ButtonSize.Small}
                  bold
                  onClick={onSearch}
                  filterChanged={dateChanged}
                  mountingStatus={mountStatus}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('order.filter_attendee_search')}
                    inputValue={filterData.name || ''}
                    onChange={onChangeText}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'id'}
                    inputType={InputType.Number}
                    labelText={t('common.orderId')}
                    inputValue={filterData.id || ''}
                    onChange={onChangeText}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'startDate'}
                    inputType={InputType.Date}
                    labelText={t('common.start_date')}
                    inputValue={filterData.startDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'endDate'}
                    inputType={InputType.Date}
                    labelText={t('common.end_date')}
                    inputValue={filterData.endDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_order_status')}
                    options={orderStatus}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => handleDropdown('SType', value)}
                    onReset={() => handleResetDropdown('SType')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_location')}
                    options={locationList}
                    selectedProps={{ key: 'locationName', value: 'id' }}
                    onChange={(value) => handleDropdown('SType2', value)}
                    onReset={() => handleResetDropdown('SType2')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_user_area')}
                    options={userAreaList}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => handleDropdown('SType3', value)}
                    onReset={() => handleResetDropdown('SType3')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_month')}
                    options={timesPerMonthList}
                    selectedProps={{ key: 'monthDuration', value: 'id' }}
                    onChange={(value) => handleDropdown('SType4', value)}
                    onReset={() => handleResetDropdown('SType4')}
                    isfilter
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <div>
              <Table
                // searchAble
                data={orderList}
                headers={headers}
                showLoading={showLoader}
                striped
                bordered
                page={Entities.order}
                supPage={Entities.attendee}
                forwardPage={Entities.meeting}
                edit={(val) => editAction(val)}
                remove={(val) => removeAction(val)}
                editSup={(val) => editSupAction(val)}
                editForwardTxt={'pageActions.attendeeOrderDetails'}
                forward={(val) => forwardAction(val)}
                goDetails={(val) => showDetailsAction(val)}
                toOperation={(val) => toOperationAction(val)}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.order.exportName.order}
                filterActionsRule={true}
                filterRule={ActionFilterRules.order}
              />
            </div>
          }
        />
      </div>
      <Toast
        stateName={'order'}
        onClose={(val) => {
          if (val) {
            dispatch(Actions.orderActions.cleanState(true));
            getList(true);
          }
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.getAll,
    content
  );
};
export default List;
