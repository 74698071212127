import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../store/redux/actions';
import { LanguagesForSite } from '../../utils/Enum';
import Modal from '../../components/Common/Modal/Modal';
import Feedback from '../../pages/Landing/Feedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flag = useSelector((state) => state.lang.flag);
  const loggedIn = useSelector((state) => state.auth.auth);
  const lang = useSelector((state) => state.lang.language);
  const currencyOptions = useSelector(
    (state) => state.preferenceOptions.currenyOptions
  );
  const currency = useSelector(
    (state) => state.preferenceOptions.preferenceCurrencyUnit
  );
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const [feedbackOn, setFeedbackOn] = useState(false);

  function handleLanguage(lang) {
    i18next.changeLanguage(lang);
    dispatch(Actions.faqActions.getAllReducer([]));
    dispatch(Actions.faqGroupActions.getAllReducer([]));
    dispatch(Actions.langActions.pickLanguage(lang));
    dispatch(Actions.langActions.setFlag(lang));
  }
  function handleSize(nav, area = undefined) {
    if (area !== undefined) {
      dispatch(Actions.onboardActions.onboardType(area));
    }
    dispatch(Actions.screenActions.checkScreenSize(!nav));
    if (nav === true) {
      dispatch(Actions.screenActions.checkScreenSize(!nav));
      const offcanvasCloseButton = document.querySelector(
        '.offcanvas .btn-close'
      );
      if (offcanvasCloseButton) {
        offcanvasCloseButton.click();
      }
    }
  }
  function handleCurrency(currencyUnit) {
    dispatch(Actions.preferenceActions.setCurrencyAction(currencyUnit, true));
  }

  useEffect(() => {
    if (loggedIn === '') {
      if (currencyOptions.length === 0) {
        dispatch(Actions.preferenceActions.getCurrencyAction());
      }

      if (levelGroup === null) {
        dispatch(Actions.preferenceActions.getLevelGroupAction(0));
      }
    }
  }, []);

  return (
    <div className="navbar navbar-expand-lg navbar-light border-bottom bg-white sticky-top">
      <div className="container">
        <nav className="navbar navbar-light bg-white p-0">
          <Link
            className="navbar-brand d-flex align-items-center col-auto "
            to="/"
          >
            <img
              className="img"
              src="/images/meet2talk-logo.svg"
              // src="/images/yeşil-siyah-logo.png"
              alt="Meet2Talk Logo"
              width="100%"
              style={{ maxWidth: '200px' }}
            />
          </Link>
        </nav>

        <button
          className="navbar-toggler d-lg-none d-sm-flex"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#collapseNavbar"
          aria-controls="collapseNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end m-0 fs-6 my-auto"
          id="collapseNavbar"
          tabIndex="-1"
          aria-labelledby="offcanvasFilterLabel"
        >
          <div className="offcanvas-header justify-content-between align-items-center">
            <a className="navbar-brand" href="/">
              <img
                className="img"
                src="/images/meet2talk-logo.svg"
                // src="/images/yeşil-siyah-logo.png"
                alt="Meet2Talk Logo"
                width="100%"
                style={{ maxWidth: '200px' }}
              />
            </a>
            <button
              type="button"
              className="btn-close me-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body align-items-center">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown px-2">
                <button
                  className="nav-link dropdown-toggle"
                  id="Learn_English"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t('navbar.learn_english')}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end text-decoration-none"
                  aria-labelledby="Learn_English"
                >
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/online"
                      onClick={() => handleSize(true, 0)}
                    >
                      {t('UserArea.0', { lng: 'en' })}
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/cafe"
                      onClick={() => handleSize(true, 1)}
                    >
                      {t('UserArea.1', { lng: 'en' })}
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/kids"
                      onClick={() => handleSize(true, 2)}
                    >
                      {t('UserArea.2', { lng: 'en' })}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown px-2">
                <button
                  className="nav-link dropdown-toggle"
                  id="Corporate"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t('navbar.corporate')}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="Corporate"
                >
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/company"
                      onClick={() => handleSize(true)}
                    >
                      {t('UserArea.3', { lng: 'en' })}
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/career/create"
                      onClick={() => handleSize(true)}
                    >
                      {t('navbarCorporate.become_a_mentor')}
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      className="nav-link"
                      to="/become-a-partner"
                      onClick={() => handleSize(true)}
                    >
                      {t('navbarCorporate.become_a_partner')}
                    </Link>
                  </li>
                </ul>
              </li>
              <Link
                className="nav-link px-2 py-2"
                to="/order/package"
                state={{ trialStatus: 2 }}
                onClick={() => handleSize(true)}
              >
                {t('navbar.packages')}
              </Link>

              <Link
                className="nav-link px-2 py-2"
                target="_blank"
                onClick={() => handleSize(true)}
                to="https://blog.meet2talk.online/"
              >
                {t('Blog')}
              </Link>
              <Link
                className="nav-link px-2 py-2"
                onClick={() => setFeedbackOn(true)}
              >
                {t('feedbackNavbar.feedback')}
              </Link>
              <Link
                className="nav-link px-2 py-2"
                to="/tiktok"
                onClick={() => handleSize(true)}
              >
                <FontAwesomeIcon icon={faTiktok} />
              </Link>
            </ul>

            <ul className="navbar-nav ms-auto align-items-lg-center">
              <li className="nav-item dropdown px-2 py-2">
                <button
                  className="btn btn-default border-0 dropdown-toggle"
                  type="button"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <img
                    className="me-2"
                    src={
                      require(flag === 'gb'
                        ? `../../assets/images/flags/gb.svg`
                        : `../../assets/images/flags/tr.svg`).default
                    }
                    width={'16px'}
                    alt={'svg'}
                  />
                  <span>{currency.symbol}</span>
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdown"
                >
                  <div className="p-2">
                    <p className="p-0"> {t('navbar.site_language')}</p>
                    <div className="dropdown">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        id="langnavbarDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {lang?.split(',')[1]}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="langnavbarDropdown"
                      >
                        {Object.keys(LanguagesForSite).map((key) => (
                          <li
                            key={key}
                            className="dropdown-item"
                            onClick={() =>
                              handleLanguage(LanguagesForSite[key])
                            }
                          >
                            {key === 'eng'
                              ? t('navbar.english')
                              : t('navbar.turkish')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="p-2">
                    <p className="p-0"> {t('navbar.currency')}</p>

                    <div className="dropdown">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        id="currencyDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {currency?.name}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="currencyDropdown"
                      >
                        {currencyOptions?.map((currency) => (
                          <li
                            key={currency.Code}
                            className="dropdown-item"
                            onClick={() => handleCurrency(currency)}
                          >
                            {currency.Name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </ul>
              </li>

              <Link
                className="nav-link px-2 py-2"
                to="/account/login"
                onClick={() => handleSize(true)}
                hidden={loggedIn !== '' ? true : false}
              >
                {t('navbar.log_in')}
              </Link>

              <Link
                className="nav-link px-2 py-2 d-lg-none d-flex"
                to="/account/register"
                onClick={() => handleSize(true)}
                hidden={loggedIn !== '' ? true : false}
              >
                {t('navbar.register')}
              </Link>

              <div className="d-lg-flex d-none align-items-center col-lg-3 col-ms-10 px-2 py-2">
                <button
                  className="btn btn-success btn-sm rounded-4"
                  hidden={loggedIn !== '' ? true : false}
                >
                  <Link
                    className="nav-link px-2 text-white text-nowrap"
                    to="/account/register"
                    onClick={() => handleSize(true)}
                  >
                    {t('navbar.register')}
                  </Link>
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        isVisible={feedbackOn}
        title={t('feedback.form_header')}
        titleMS={2}
        styleProps={{ maxWidth: 450, borderRadius: 15 }}
        content={<Feedback handleModal={(val) => setFeedbackOn(val)} />}
        onClose={() => setFeedbackOn(false)}
      />
    </div>
  );
};
export default Navbar;
