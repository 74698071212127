import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { saveAs } from 'file-saver';
import Button from '../../../components/Common/Button/Button';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceGrinHearts, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { storageKeys, storageValues } from '../../../utils/Enum';
import findMyPath from '../../../hooks/useFindMyPath';

const Tiktok = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth);
  const userInfo = useSelector((state) => state.user.getById);
  const trialUsed = userInfo.freeTrialUsed;
  const [pdfs, setPdfs] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState({
    chapter: 1,
    url: '/Modal, Infinitives and Imperatives.pdf',
    number: 1,
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState(null);
  const pdfModal = useRef();

  useEffect(() => {
    const pdfFiles = [
      '/Modal, Infinitives and Imperatives.pdf',
      '/Articles, Nouns, Pronouns, and Determiners.pdf',
      '/There, It, Adjectives, and Adverbs.pdf',
    ];
    fetchAndRenderMultiplePDFs(pdfFiles);
  }, []);

  const fetchAndRenderMultiplePDFs = async (pdfFiles) => {
    try {
      const allPdfs = [];
      for (const url of pdfFiles) {
        const pdfDoc = await pdfjsLib.getDocument(url).promise;
        const numPages = pdfDoc.numPages;
        const pagesPromises = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          pagesPromises.push(pdfDoc.getPage(pageNum));
        }

        const pages = await Promise.all(pagesPromises);
        allPdfs.push({ pdfDoc, pages, url }); // Store each PDF with its pages and URL
      }

      setPdfs(allPdfs);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };

  const openModal = useCallback((page, i, chapter, url) => {
    setSelectedPage(page);
    setSelectedEpisode({ chapter: chapter, url: url, number: i + 1 });
    setSelectedPageIndex(i);
    if (pdfModal.current) {
      pdfModal.current.open();
    }
  }, []);
  const goToPreviousPage = () => {
    if (selectedPageIndex > 0) {
      const newIndex = selectedPageIndex - 1;
      setSelectedPage(pdfs[selectedEpisode.chapter - 1].pages[newIndex]);
      setSelectedPageIndex(newIndex);
    }
  };

  const goToNextPage = () => {
    if (
      selectedPageIndex <
      pdfs[selectedEpisode.chapter - 1].pages.length - 1
    ) {
      const newIndex = selectedPageIndex + 1;
      setSelectedPage(pdfs[selectedEpisode.chapter - 1].pages[newIndex]);
      setSelectedPageIndex(newIndex);
    }
  };

  function editContent(
    text,
    bold = false,
    colorMe = undefined,
    href = undefined,
    boldWords = []
  ) {
    const regexPattern = /(.*?)\s*%([^%]+)%\s*(.*)/;
    const matchResult = text.match(regexPattern);

    if (colorMe !== undefined) {
      const regex = /%([^%]*)%/;
      const match = text.match(regex);
      const extractedText = match ? match[1] : '';
      return (
        <span>
          {matchResult?.[1]}
          <span className={`text-custom-${colorMe}`}>
            <strong
              role={href === undefined ? 'none' : 'button'}
              onClick={() => navigate(href)}
            >{` ${extractedText} `}</strong>
          </span>
          {matchResult?.[3]}
        </span>
      );
    }
    if (bold) {
      return (
        <span>
          <strong>{`${text}`}</strong>
        </span>
      );
    }
    const boldText = matchResult ? matchResult[2] : '';

    let additionalWord = '';
    if (boldWords.includes(boldText)) {
      additionalWord = boldText;
    }

    if (matchResult) {
      const beforeText = matchResult[1];
      const afterText = matchResult[3];

      return (
        <span>
          {beforeText}
          <strong className="fw-bold"> {additionalWord} </strong>
          {afterText}
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  }

  return (
    <div className="container-fluid mt-5">
      <Card
        body={
          <React.Fragment>
            <div className="d-flex justify-content-center text-start">
              {auth === '' ? (
                <div className="col-lg-4 col-md-6 col-12">
                  <p>
                    {editContent(t('tiktok.txt'), undefined, false, undefined, [
                      t('tiktok.txtCompanies'),
                    ])}
                  </p>
                  <p>{t('tiktok.txt1')}</p>
                  <p>
                    {editContent(
                      t('tiktok.txt2'),
                      undefined,
                      'danger',
                      '/account/login'
                    )}
                    {editContent(
                      t('tiktok.txt3'),
                      undefined,
                      'danger',
                      '/account/register'
                    )}
                  </p>
                  <p>{editContent(t('tiktok.txt4'), true)}</p>
                  <p>{editContent(t('tiktok.txt5'), true)}</p>
                </div>
              ) : (
                <div className="col-lg-5 col-md-8 col-12">
                  {
                    <p>
                      <strong>{t('tiktok.txt6')}</strong>
                      <FontAwesomeIcon
                        icon={faFaceGrinHearts}
                        size="2x"
                        className="text-warning ms-2"
                      />
                    </p>
                  }
                  <p> {editContent(t('tiktok.txt7'))}</p>
                  <p>
                    {editContent(
                      t('tiktok.txt8'),
                      undefined,
                      false,
                      undefined,
                      [t('tiktok.txtCompanies')]
                    )}
                  </p>
                  <p>{editContent(t('tiktok.txt9'), true)}</p>
                  <p>
                    {editContent(
                      t('tiktok.txt10'),
                      undefined,
                      'danger',
                      !trialUsed ? findMyPath('order/triallesson') : undefined
                    )}
                    {editContent(
                      t('tiktok.txt11'),
                      undefined,
                      'danger',
                      findMyPath('order/package')
                    )}
                  </p>
                </div>
              )}
            </div>
            {auth !== '' && (
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-between gap-5">
                  {!trialUsed ? (
                    <Button
                      text={t('button.get_free_session')}
                      type={ButtonType.CalendarSelectButton}
                      textColor={TextColors.Light}
                      size={ButtonSize.Small}
                      onClick={() => navigate(findMyPath('order/triallesson'))}
                    />
                  ) : (
                    <Button
                      text={t('button.freeTrialAlreadyUsed')}
                      type={ButtonType.Secondary}
                      textColor={TextColors.Light}
                      size={ButtonSize.Small}
                      disabled={true}
                    />
                  )}
                  <Button
                    text={t('button.get_subsccription')}
                    type={ButtonType.Successbg}
                    textColor={TextColors.Light}
                    size={ButtonSize.Small}
                    onClick={() => navigate(findMyPath('order/package'))}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="d-flex flex-wrap justify-content-center">
                {pdfs?.map((pdf, index) => (
                  <div className="d-flex flex-column align-items-center">
                    <HeadTags
                      strong
                      hSize={6}
                      marginTop={3}
                      text={t('tiktok.episode', { No: index + 1 })}
                    />
                    <p className="mt-2">
                      <strong>
                        {pdf?.url?.replace(/^\/+/, '').replace(/\.pdf$/, '')}
                      </strong>
                    </p>
                    {pdf?.pages?.length > 0 && (
                      <PageCard
                        key={0}
                        page={pdf?.pages[0]}
                        onClick={() =>
                          openModal(pdf?.pages[0], 0, index + 1, pdf.url)
                        }
                        pages={pdf?.pages?.length}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        }
      />
      <ModalInsideComponent
        ref={pdfModal}
        title={auth === '' && t('tiktok.register')}
        titleComponent={
          auth === '' ? undefined : (
            <div className="d-flex flex-column align-items-center">
              <HeadTags
                strong
                hSize={6}
                text={`${t('tiktok.episode', {
                  No: selectedEpisode.chapter,
                })} - ${selectedPageIndex + 1}`}
              />
              <p>
                <strong>
                  {selectedEpisode?.url
                    ?.replace(/^\/+/, '')
                    .replace(/\.pdf$/, '')}
                </strong>
              </p>{' '}
            </div>
          )
        }
        titleCentered={true}
        child={
          <React.Fragment>
            {selectedPage && auth !== '' && (
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  type={
                    selectedPageIndex === 0
                      ? ButtonType.Secondary
                      : ButtonType.Successbg
                  }
                  iconColor={TextColors.Light}
                  icon={ButtonIcon.ArrowLeft}
                  onClick={goToPreviousPage}
                  disabled={selectedPageIndex === 0}
                />
                <PageCard
                  page={selectedPage}
                  scale={1.5}
                  reset={true}
                  url={selectedEpisode.url}
                />
                <Button
                  type={
                    selectedPageIndex ===
                    pdfs[selectedEpisode.chapter - 1].pages.length - 1
                      ? ButtonType.Secondary
                      : ButtonType.Successbg
                  }
                  iconColor={TextColors.Light}
                  icon={ButtonIcon.ArrowRight}
                  onClick={goToNextPage}
                  disabled={
                    selectedPageIndex ===
                    pdfs[selectedEpisode.chapter - 1].pages.length - 1
                  }
                />
              </div>
            )}
            {selectedPage && auth === '' && (
              <div className="d-flex justify-content-evenly">
                <div className="col-4 me-2">
                  <Button
                    text={t('navbar.log_in')}
                    type={ButtonType.Success}
                    outline
                    bold
                    onClick={() => {
                      sessionStorage.setItem(
                        storageKeys.tiktok,
                        storageValues.tiktok
                      );
                      navigate('/account/login');
                      pdfModal.current.close();
                    }}
                  />
                </div>
                <div className="col-4 ms-2">
                  <Button
                    text={t('navbar.register')}
                    type={ButtonType.Successbg}
                    bold
                    textColor={TextColors.Light}
                    onClick={() => {
                      sessionStorage.setItem(
                        storageKeys.tiktok,
                        storageValues.tiktok
                      );
                      navigate('/account/register');
                      pdfModal.current.close();
                    }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        }
        onClose={() => {
          setSelectedPage(null);
          setSelectedPageIndex(null);
          if (pdfModal.current) {
            pdfModal.current.close();
          }
        }}
        maxWidth={800}
      />
    </div>
  );
};

const PageCard = ({
  page,
  onClick,
  scale = 0.5,
  reset = false,
  pages,
  url,
}) => {
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const downloadPDF = () => {
    const cleanedUrl = url.replace(/^\/|\.pdf$/g, '');
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, cleanedUrl);
      })
      .catch((error) => console.error('Error downloading PDF:', error));
  };

  useEffect(() => {
    const renderPage = async () => {
      if (page) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;
      }
    };

    renderPage();
  }, [page, scale]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
      }}
    >
      <div
        onClick={onClick}
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
          width: reset ? 'auto' : '320px',
          height: reset ? 'auto' : '200px',
          backgroundColor: '#fff',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      {/* {!reset && <HeadTags strong hSize={6} marginTop={3} text={pages} />} */}
      {reset && (
        <Button
          onClick={downloadPDF}
          bold
          icon={faFilePdf}
          iconSide
          iconColor={TextColors.Light}
          type={ButtonType.Successbg}
          textColor={TextColors.Light}
          text={t('tiktok.download')}
        />
      )}
    </div>
  );
};

export default Tiktok;
